<template>
  <div class="animated fadeIn">
    <b-form @submit.prevent="forgetPassword"
            novalidate
            autocomplete="off">
      <b-card>
        <div slot="header">
          <h2>Forgot your password?</h2>
        </div>

        <b-row>
          <b-col>
            <b-form-group>
              <label for="newPassword" class="eden__label__required">Username</label>
              <b-form-input v-model="form.username"
                            id="username"
                            ref="username"
                            placeholder="Username"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col cols="12" xl="2" lg="3" md="6">
            <b-form-group>
              <b-button type="submit"
                        ref="save"
                        id="save"
                        variant="primary"
                        class="w-100">
                <i class="fa fa-user mr-2"></i>
                Reset Password
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-form>
  </div>
</template>
<script>
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'

  export default {
    mixins: [
      validationMixin,
    ],

    data() {
      return {
        form: {
          username: null,
        },
      }
    },

    validations: {
      form: {
        username: { required },
      },
    },

    methods: {
      async forgetPassword() {
        // Form validation
        this.$v.form.$touch()

        if (this.$v.form.$anyError) {
          this.$toasted.global.error({
            message: 'Please correct errors in form',
          })
          return
        }

        const loader = this.$loading.show()

        try {
          const { data } = await this.$request({
            url: '/api/auth/forgot-password',
            method: 'post',
            data: this.form,
          })
          this.$toasted.global.success({
            message: 'An email has been sent with instructions to reset your password.',
          })
          this.$router.push('/login')
        } catch (e) {
          this.$log.error('Error sending forgot password record', e)
          this.$toasted.global.error({
            message: e.message,
          })
          return
        } finally {
          loader.hide()
        }
      },
    },
  }
</script>
